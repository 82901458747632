import Helmet from "react-helmet"
import React from "react"
import OptionalOffer from "@tightrope/optionaloffer"
import Optionalofferint from "src/components/modules/optionalofferint"


export default function Optional() {
  const DeData = import("./data/opoffergerman.json");
  const FrData = import("./data/opofferfrench.json");
  module = <OptionalOffer></OptionalOffer>;
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
     let lang = navigator.language.slice(0,2);
     if(lang == 'de'){
        module = <Optionalofferint data={DeData} language='de'></Optionalofferint>
      }
      else if(lang == 'fr'){
        module = <Optionalofferint data={FrData} language='fr'></Optionalofferint>
      }
   }
  return(
    <section>
      <Helmet>
      <style type="text/css">{`
        .footer{ position: relative;
          margin-top: 25px;
        }

        .footer a {
          padding: 0px 1px;
        }

        body {
          background: #E1EEF8;
          background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
          background-repeat: no-repeat;
          background-size: cover;
          height: 100vh;
          min-height: 728px;
        }

        .optional-module--optionaloffer .optional-module--ing {
          background: #ffffff80;
          border-radius: 4px;
          border: 1px solid #e1e6e9;
        }

        .optional-module--optionaloffer .optional-module--stepsContainer {
          -webkit-filter: grayscale(40%);
          filter: grayscale(40%);
        }

        .optional-module--optionaloffer button {
          border: none;
          background: #0078d4;
          color: #fff;
          font-weight: 500;
          font-size: 20pt;
          padding: 15px 75px;
          border-radius: 50px;
          cursor: pointer;
          letter-spacing: .5px;
          transition: .2s ease;
      }

      .optional-module--optionaloffer button:hover {
        transform: scale(1.1);
        background: #0078d4;
      }

      .optional-module--optionaloffer button svg {
        padding: 0 0 7px 2px;
      }

      .optional-module--optionaloffer .optional-module--lcwsTop {
        background: #ffffff80;
        box-shadow: none;
        border-bottom: 1px solid #e1e6e9;
      }

      .optional-module--optionaloffer .optional-module--boxlefttop {
        opacity: 1;
      }

      .optional-module--optionaloffer .optional-module--offerLeft {
        width: 350px;
        margin-right: 19px;
      }

      .optional-module--optionaloffer .optional-module--offerRight {
        width: 350px;
      }
        }

    `}</style>
        <body class="white" />
        <title>Optional Offer- Get Freshy Homepage Newtab- freshysearch.com</title>
      </Helmet>
      {module}
    </section>
  )
}
